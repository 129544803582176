import React, { Component } from "react";
import Helmet from "react-helmet";
import config from "../../utils/siteConfig";

// Services
import intl from "../../services/intl";

class GeneralTags extends Component {
  static defaultProps = {
    // TODO: Internationalize these strings.
    defaultTitle: config.siteTitle,
    title: config.siteTitle,
    description: config.siteDescription,
    image: {
      url: config.siteUrl + config.shareImage,
      width: config.shareImageWidth,
      height: config.shareImageHeight,
    },
    pagePath: `${config.siteUrl}/`,
    pageUrl: config.siteUrl,
    noIndex: false,
  };

  generateLinks() {
    const { pagePath } = this.props;
    const { defaultLocale, activeLocales } = intl;
    const domain = process.env.GATSBY_URL;
    const printHref = (domain, path, locale) =>
      [domain, path, locale].filter(h => h).join("/");

    return [
      {
        rel: "alternate",
        hrefLang: "x-default",
        href: printHref(domain, pagePath, defaultLocale.path),
      },
      ...activeLocales.map((locale) => ({
        rel: "alternate",
        hrefLang: locale.locale,
        href: printHref(domain, pagePath, locale.path),
      })),
    ];
  }

  render() {
    const {
      defaultTitle,
      title,
      description,
      image,
      image: { url: imgUrlPartial, width: imgWidth, height: imgHeight },
      pagePath,
      noIndex,
      ogTitle,
    } = this.props;

    const pageUrl =
      pagePath.length > 0
        ? `${this.props.pageUrl}/${pagePath}`
        : this.props.pageUrl;

    if (!title || !description || !image) {
      console.warn(
        `Information missing from page SEO : ${title ? "" : "--title "} ${
          description ? "" : "--description "
        } ${image ? "" : "--image "}`,
      );
    }

    let htmlAttributes = {
      lang: intl.siteLang,
    };

    const imgUrl = `https:${imgUrlPartial}`;

    // Default Website Schema
    const schemaOrgJSONLD = [
      {
        "@context": "http://schema.org",
        "@type": "WebSite",
        url: config.siteUrl,
        name: config.siteTitle,
        alternateName: config.siteTitleAlt ? config.siteTitleAlt : "",
      },
      {
        "@context": "http://schema.org",
        "@type": "WebPage",
        url: pageUrl,
        name: title,
        alternateName: config.siteTitleAlt || "",
      },
    ];

    let genericMeta = [
      { name: "image", content: imgUrl },
      { name: "description", content: description },
    ];

    if (noIndex === true) {
      genericMeta.push({ name: "robots", content: "noindex" });
    }

    let openGraphMeta = [
      { property: "og:locale", content: intl.ogLocale },
      { property: "og:title", content: ogTitle || title },
      { property: "og:description", content: description },
      { property: "og:image", content: imgUrl },
      { property: "og:image:width", content: imgWidth },
      { property: "og:image:height", content: imgHeight },
      { property: "og:type", content: "website" },
      { property: "og:site_name", content: defaultTitle },
      { property: "og:url", content: pageUrl },
    ];

    let facebookMeta = [
      { property: "fb:pages", content: config.fbPageId || "" },
    ];

    let twitterMeta = [
      { name: "twitter:title", content: title },
      { name: "twitter:description", content: description },
      { name: "twitter:image", content: imgUrl },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:creator", content: config.userTwitter || "" },
    ];

    let meta = [
      ...genericMeta,
      ...openGraphMeta,
      ...facebookMeta,
      ...twitterMeta,
    ];

    const links = this.generateLinks();

    return (
      <Helmet
        htmlAttributes={htmlAttributes}
        title={title}
        defaultTitle={defaultTitle}
        meta={meta}
        link={links}
        defer={false}
      >
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
      </Helmet>
    );
  }
}

export default GeneralTags;
